import React from 'react';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    angledSection: {
        overflow: 'hidden',
        position: 'relative',
    },
    topAngle: {
        borderTop: '32px solid #fff',
        borderRight: '150vw solid transparent',
        content: '""',
        height: '0',
        left: '-25vw',
        position: 'absolute',
        top: '0',
        width: '150vw',
        zIndex: '1',
    },
    topAngleReversed: {
        borderTop: '32px solid #fff',
        borderLeft: '150vw solid transparent',
        content: '""',
        height: '0',
        left: '-25vw',
        position: 'absolute',
        top: '0',
        width: '150vw',
        zIndex: '1',
    },
    bottomAngle: {
        borderBottom: '32px solid #fff',
        borderLeft: '150vw solid transparent',
        bottom: '0',
        content: '""',
        height: '0',
        left: '-25vw',
        position: 'absolute',
        width: '150vw',
        zIndex: '1',
    },
    bottomAngleReversed: {
        borderBottom: '32px solid #fff',
        borderRight: '150vw solid transparent',
        bottom: '0',
        content: '""',
        height: '0',
        left: '-25vw',
        position: 'absolute',
        width: '150vw',
        zIndex: '1',
    },
}));

const AngledSection = ({
    children,
    bottomColor,
    color = '#fff',
    isAngledBottom = true,
    isAngledTop = true,
    isReversedBottom = false,
    isReversedTop = false,
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.angledSection}>
            {isAngledTop && <Box className={isReversedTop ? classes.topAngleReversed : classes.topAngle} style={{ borderTopColor: color }} />}
            {children}
            {isAngledBottom && <Box className={isReversedBottom ? classes.bottomAngleReversed : classes.bottomAngle} style={{ borderBottomColor: bottomColor || color }} />}
        </Box>
    );
};

export default AngledSection;