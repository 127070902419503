import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { CardActionArea } from 'gatsby-theme-material-ui';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

import AngledSection from '../components/layout/AngledSection';
import FadeIn from '../components/transitions/FadeIn';
import FadeInUp from '../components/transitions/FadeInUp';
import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles({
    media: {
        height: 140,
    },
    mainContent: {
        minHeight: '500px'
    }
});

const checkExcerptChild = (child) => {
    return child.type === 'text' && child.value !== '\n';
};

const filterExcerptChildren = (children) => {
    return children.filter(child => checkExcerptChild(child)).map(child => child.value).join(' ');
}

// excerptAst is a huge JSON blob that contains a lot of information, so
// this function and the series of functions it calls formats that information
// into one string that we can show as the case study excerpt.
const renderExcerpt = (excerptAst) => {
    return excerptAst;
    return excerptAst.children.map(excerpt => {
        if (Array.isArray(excerpt.children)) {
            return filterExcerptChildren(excerpt.children);
        } else if (checkExcerptChild(excerpt)) {
            return excerpt.value;
        }
        return '';
    }).join(' ');
}

const CaseStudyList = ({ data: { allMdx } }) => {
    const classes = useStyles();

    return (
        <Layout>
            <SEO title={`Case Studies`} description={`SpeedyDock provides software solutions for dry stack marinas and boat clubs. See how other marinas and boat clubs benefit from SpeedyDock with these case studies.`} />
            <AngledSection isAngledTop={false}>
                <Box pb={8} pt={16} bgcolor="secondary.dark" color="primary.main">
                    <Container maxWidth="md">
                        <FadeIn>
                            <Typography variant="h3" component="h1" gutterBottom>
                                Case Studies
                            </Typography>
                        </FadeIn>
                    </Container>
                </Box>
            </AngledSection>
            <Box pb={8} pt={8} className={classes.mainContent}>
                <Container maxWidth="md">
                    <FadeInUp>
                        <Grid container spacing={6}>
                        {
                                allMdx.edges.map(({ node: { frontmatter, excerpt } }) => ( //excerptAst
                                <Grid key={frontmatter.slug} item xs={12} sm={12} md={6}>
                                    <Card>
                                        <CardActionArea to={frontmatter.slug}>
                                            <CardMedia
                                                className={classes.media}
                                                image={frontmatter.imgUrl}
                                                title={frontmatter.title}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {frontmatter.title}
                                                </Typography>
                                                <Typography>
                                                    {renderExcerpt(excerpt)}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))
                        }
                        </Grid>
                    </FadeInUp>
                </Container>
            </Box>
        </Layout>
    );
};

                    //excerptAst -- was under node
export const pageQuery = graphql`
    {
        allMdx(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 50
        ) {
            edges {
                node {
                    id
                    excerpt
                    frontmatter {
                        imgUrl
                        slug
                        title
                    }
                }
            }
        }
    }
`;

export default CaseStudyList;
